<template>
  <div>
    <NavBar></NavBar>
    <b-container>
      <br>
      <h1>Lesson Preview</h1>
      <hr>
      <Loader v-if="loading"></Loader>
      <div v-else>
        <OneLesson :tutorial="tutorial" :lesson="lesson" :show-previous-next="false"></OneLesson>
        <!-- start lesson -->
        <!--start course button -->
        <div class="row mt-4">
          <div class="col-3"></div>
          <div class="col">
            <StartCourseButton :product-id="tutorial.productId"></StartCourseButton>
          </div>
          <div class="col-3"></div>
        </div>
        <!-- Table of contents -->
        <TableOfContentTutorial :lessons="lessons"
                                :tutorial-id="tutorial.id"
                                v-on:click-lesson="onClickLesson"
                                :is-promotional="true"
        ></TableOfContentTutorial>
      </div>

    </b-container>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Loader from "@/components/Loader";
import Consts from "@/consts";
import WebService from "@/webservice";
import OneLesson from "@/components/OneLesson";
import TableOfContentTutorial from "@/components/TableOfContentTutorial";
import StartCourseButton from "@/components/StartCourseButton";
export default {
  name: "LessonPreview",
  components: {StartCourseButton, TableOfContentTutorial, OneLesson, Loader, NavBar},
  data() {
    return {
      routeNames: Consts.ROUTE_NAMES,
      loading: false,
      lesson:null,
      tutorial: null,
      lessons: null
    }
  },
  props: {
    tutorialId: String,
    lessonId: String
  },
  created() {
    this.fetchData()
  },
  methods: {
    onClickLesson(lesson){
      this.lessonId = lesson.id
      this.fetchData()
    },
    onCompleteLesson(lesson){
      console.log(lesson)
    },
    fetchData(){
      this.loading = true
      WebService.getOneLessonPublic(this.tutorialId, this.lessonId,this.$route.query.jwtExcerpt).then((res)=>{
        this.lesson = res.data.lesson
        this.tutorial = res.data.tutorial
        this.lessons = res.data.lessons
        this.loading = false
      }).catch((err)=> {
        console.error(err)
        this.loading = false
        this.makeToast("Error", "An unexpected error has occurred, please try again later", "danger")
      })
    }
  }
}
</script>

<style scoped>

</style>